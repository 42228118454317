import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

const LogoField = (props) => {
    const { source, text, type, location, additionalText, extension, height, width, fallback } = props;
    const record = useRecordContext(props);

    const sourceValue = get(record, source);
    const textValue = get(record, text);

    const ext = extension ? extension : 'svg';
    const heightPx = `${height ? height : 24}px`;
    const minWidthPx = `${width ? width : 24}px`;
    const imageSrc = `images/${type}/${sourceValue}.${ext}`;
    const fallbackSrc = fallback ? `images/${type}/${fallback}` : 'images/BADGE.svg';

    if (location === 'right')
        return (
            <Typography component="span" variant="body2">
                {textValue} {additionalText} <img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} alt={textValue} title={textValue} style={{ height: heightPx, minWidth: minWidthPx, margin: '0 0 0 2px', verticalAlign: 'middle', objectFit: 'contain' }} />
            </Typography>
        );
    else
        return (
            <Typography component="span" variant="body2">
                <img src={imageSrc} onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} alt={textValue} title={textValue} style={{ height: heightPx, minWidth: minWidthPx, margin: '0 2px 0 0', verticalAlign: 'middle', objectFit: 'contain' }} /> {textValue} {additionalText}
            </Typography>
        );
}  

LogoField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

LogoField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default LogoField;