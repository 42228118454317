import * as React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { Login } from 'react-admin';

const useLoginStyles = makeStyles(
    (theme) => ({
        main: {
            backgroundColor: '#212529',
            backgroundImage: 'url("map-image.png")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'unset'
        }
    }),
    { name: 'RaLogin' }
);

const useAvatarStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: 'transparent',
            height: '60px',
            overflow: 'unset',
            '& svg': {
                display: 'none'
            },
            '&:before' :{
                content: 'url("icon.png")',
                transform: 'scale(.05)'
            }
        }
    }),
    { name: 'MuiAvatar' }
);

const useFormStyles = makeStyles(
    (theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        actions: {
            display: 'block',
            textAlign: 'center'
        },
        button: {
            backgroundColor: '#fed136',
            color: '#fff',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: '#212529',
                color: '#fed136'
            }
        },
        icon: {
            textAlign: 'center'
        }
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginPage = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const loginClasses = useLoginStyles(props);
    const formClasses = useFormStyles(props);
    useAvatarStyles(props);

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = values => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        :  error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    }
                );
            });
    };

    return (
        <Login classes={loginClasses}>
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={formClasses.form}>
                            <div className={formClasses.input}>
                                <Field
                                    autoFocus
                                    id="username"
                                    name="username"
                                    component={Input}
                                    label={translate('ra.auth.username')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={formClasses.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                    autoComplete="current-password"
                                />
                            </div>
                        </div>
                        {loading && (
                            <div className={formClasses.icon}>
                                <CircularProgress size={18} thickness={2} />
                            </div>
                        )}
                        <CardActions className={formClasses.action} ref={(node) => {
                                if (node) {
                                    node.style.setProperty("display", "block", "important");
                                    node.style.setProperty("text-align", "center", "important");
                                }
                            }}>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={formClasses.button}
                                id="signIn"
                            >Login</Button>
                        </CardActions>
                    </form>
                )}
            />
        </Login>
    );
};

LoginPage.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginPage;