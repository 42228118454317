import * as React from "react";
import { Admin, Resource } from 'react-admin';
import Layout from './Layout';
import LoginPage from './LoginPage';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { CompetitionList, CompetitionShow, CompetitionEdit, CompetitionCreate } from './views/competitions';
import { SeasonList, SeasonShow, SeasonEdit, SeasonCreate } from './views/seasons';
import { CompetitionSeasonList, CompetitionSeasonShow, CompetitionSeasonEdit, CompetitionSeasonCreate, CompetitionSeasonRoundAdd } from './views/competitionSeasons';
import { LadderList } from './views/ladder';
import { MatchList, MatchShow, MatchCreate, MatchEdit, MatchLineupEdit, MatchEventAdd } from './views/matches';
import { TeamResultList } from './views/teamResults';
import { PlayerList, PlayerShow, PlayerEdit, PlayerCreate } from './views/players';
import { CoachList, CoachShow, CoachEdit, CoachCreate } from './views/coaches';
import { RefereeList, RefereeShow, RefereeEdit, RefereeCreate } from './views/referees';
import { TeamStatList, TeamStatShow, PlayerStatList, PlayerStatShow, PointsByQuarterList, DrawDifficultyList } from './views/stats';
import { TeamList, TeamShow, TeamEdit, TeamCreate } from './views/teams';
import { VenueList, VenueShow, VenueEdit, VenueCreate } from './views/venues';
import { VenueLocationList, VenueLocationShow, VenueLocationEdit, VenueLocationCreate } from './views/venueLocations';
import CompetitionIcon from '@material-ui/icons/SportsRugby';
import CompetitionSeasonIcon from '@material-ui/icons/EmojiEvents';
import SeasonIcon from '@material-ui/icons/CalendarToday';
import LadderIcon from '@material-ui/icons/FormatListNumbered';
import MatchIcon from '@material-ui/icons/ConfirmationNumber';
import CoachIcon from '@material-ui/icons/RecordVoiceOver';
import PlayerIcon from '@material-ui/icons/PeopleAlt';
import PlayerStatIcon from '@material-ui/icons/ShowChart';
import RefereeIcon from '@material-ui/icons/Sports';
import TeamResultIcon from '@material-ui/icons/EventAvailable';
import TeamStatIcon from '@material-ui/icons/BarChart';
import PointsByQuarterIcon from '@material-ui/icons/Exposure';
import DrawDifficultyIcon from '@material-ui/icons/ImportExport';
import VenueIcon from '@material-ui/icons/LocalConvenienceStore';
import VenueLocationIcon from '@material-ui/icons/LocationOn';

const TeamIcon = () => <img src='images/BADGE.svg' alt="Team" title="Teams" style={{width:24}} />;

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage} layout={Layout}>
        <Resource
            name="match"
            options={{ category: "Competition", label: "Matches" }}
            icon={MatchIcon}
            list={MatchList}
            show={MatchShow}
            create={MatchCreate}
            edit={MatchEdit}
        />
        <Resource
            name="ladder"
            options={{ category: "Competition", label: "Ladders" }}
            icon={LadderIcon}
            list={LadderList}
        />
        <Resource
            name="team-results"
            options={{ category: "Competition", label: "Team Results" }}
            icon={TeamResultIcon}
            list={TeamResultList}
        />
        <Resource
            name="stat/teams"
            options={{ category: "Stats", label: "Team Stats" }}
            icon={TeamStatIcon}
            list={TeamStatList}
            show={TeamStatShow}
        />
        <Resource
            name="stat/players"
            options={{ category: "Stats", label: "Player Stats" }}
            icon={PlayerStatIcon}
            list={PlayerStatList}
            show={PlayerStatShow}
        />
        <Resource
            name="stat/points-by-quarter"
            options={{ category: "Stats", label: "Points By Quarter" }}
            icon={PointsByQuarterIcon}
            list={PointsByQuarterList}
        />
        <Resource
            name="stat/draw-difficulty"
            options={{ category: "Stats", label: "Draw Difficulty" }}
            icon={DrawDifficultyIcon}
            list={DrawDifficultyList}
        />
        <Resource
            name="competition-season"
            options={{ category: "Admin", label: "Competition Seasons" }}
            icon={CompetitionSeasonIcon}
            list={CompetitionSeasonList}
            show={CompetitionSeasonShow}
            edit={CompetitionSeasonEdit}
            create={CompetitionSeasonCreate}
        />
        <Resource
            name="competition"
            options={{ category: "Admin", label: "Competitions" }}
            icon={CompetitionIcon}
            list={CompetitionList}
            show={CompetitionShow}
            edit={CompetitionEdit}
            create={CompetitionCreate}
        />
        <Resource
            name="season"
            options={{ category: "Admin", label: "Seasons" }}
            icon={SeasonIcon}
            list={SeasonList}
            show={SeasonShow}
            edit={SeasonEdit}
            create={SeasonCreate}
        />
        <Resource
            name="team"
            options={{ category: "Admin", label: "Teams" }}
            icon={TeamIcon}
            list={TeamList}
            show={TeamShow}
            edit={TeamEdit}
            create={TeamCreate}
        />
        <Resource
            name="player"
            options={{ category: "Admin", label: "Players" }}
            icon={PlayerIcon}
            list={PlayerList}
            show={PlayerShow}
            edit={PlayerEdit}
            create={PlayerCreate}
        />
        <Resource
            name="coach"
            options={{ category: "Admin", label: "Coaches" }}
            icon={CoachIcon}
            list={CoachList}
            show={CoachShow}
            edit={CoachEdit}
            create={CoachCreate}
        />
        <Resource
            name="referee"
            options={{ category: "Admin", label: "Referees" }}
            icon={RefereeIcon}
            list={RefereeList}
            show={RefereeShow}
            edit={RefereeEdit}
            create={RefereeCreate}
        />
        <Resource
            name="venue"
            options={{ category: "Admin", label: "Venues" }}
            icon={VenueIcon}
            list={VenueList}
            show={VenueShow}
            edit={VenueEdit}
            create={VenueCreate}
        />
        <Resource
            name="venue-location"
            options={{ category: "Admin", label: "Venue Locations" }}
            icon={VenueLocationIcon}
            list={VenueLocationList}
            show={VenueLocationShow}
            edit={VenueLocationEdit}
            create={VenueLocationCreate}
        />
        <Resource
            name="match-lineup"
            edit={MatchLineupEdit}
        />
        <Resource
            name="match-events"
            edit={MatchEventAdd}
        />
        <Resource
            name="rounds"
            edit={CompetitionSeasonRoundAdd}
        />
        <Resource name="match-event-list" />
        <Resource name="match-weather" />
        <Resource name="match-type" />
        <Resource name="score-type" />
        <Resource name="weather-location" />
        <Resource name="player-active" />
        <Resource name="coach-active" />
        <Resource name="referee-active" />
    </Admin>
);

export default App;