import * as React from "react";
import PropTypes from 'prop-types';
import { DateField, useRecordContext } from 'react-admin';

const DateTimeField = (props) => {
    const { source, sourceIfNull, label } = props;
    const record = useRecordContext(props);

    return <DateField source={record[source] === null ? sourceIfNull : source} label={label} showTime locales="sv-SE" />;
}  

DateTimeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

DateTimeField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default DateTimeField;