import * as React from "react";
import { SelectInput } from 'react-admin';

import UndefinedIcon from '@material-ui/icons/HelpOutline';
import LeftIcon from '@material-ui/icons/ArrowBack';
import MiddleIcon from '@material-ui/icons/ArrowUpward';
import RightIcon from '@material-ui/icons/ArrowForward';
import RoamingIcon from '@material-ui/icons/SyncAlt';

const PositionLocationSelect = (props) => {
	const { source, label, style, defaultValue, validate } = props;

    const iconStyle = { height: '18px', margin: '0 2px 0 0', padding: '0', verticalAlign: 'middle' };

    const locations = [
        { id: 0, name: 'Undefined' },
        { id: 1, name: 'Left' },
        { id: 2, name: 'Middle' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Roaming' }
    ];

    const OptionTextRenderer = ({ record }) => {
        const Icon = () => {
            switch(record.name) {
                case 'Left':
                    return <LeftIcon style={iconStyle} />;
                case 'Middle':
                    return <MiddleIcon style={iconStyle} />;
                case 'Right':
                    return <RightIcon style={iconStyle} />;
                case 'Roaming':
                    return <RoamingIcon style={iconStyle} />;
                default:
                    return <UndefinedIcon style={iconStyle} />;
            }
        }
        return <span><Icon />{record.name}</span>;
    };
    
    return <SelectInput
		source={source}
		label={label}
        defaultValue={defaultValue}
		validate={validate}
		choices={locations}
		optionText={<OptionTextRenderer />}
		optionValue="name"
        style={style} />;
};

export default PositionLocationSelect;