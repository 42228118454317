import * as React from "react";
import { SelectInput, required } from 'react-admin';

import PreGameIcon from '@material-ui/icons/AccessTime';
import FirstHalfIcon from '@material-ui/icons/AvTimer';
import HalfTimeIcon from '@material-ui/icons/PauseCircleOutline';
import SecondHalfIcon from '@material-ui/icons/AvTimer';
import ExtraTimeIcon from '@material-ui/icons/AddCircleOutline';
import FullTimeIcon from '@material-ui/icons/CheckCircleOutline';

const MatchStatusSelect = (props) => {
	const { source, label } = props;

    const iconStyle = { height: '20px', margin: '0 2px 0 0', padding: '0', verticalAlign: 'middle' };

    const statuses = [
        { id: 0, name: 'Pre Game' },
        { id: 1, name: 'First Half' },
        { id: 2, name: 'Half Time' },
        { id: 3, name: 'Second Half' },
        { id: 4, name: 'Extra Time' },
        { id: 5, name: 'Full Time' }
    ];

    const OptionTextRenderer = ({ record }) => {
        const Icon = () => {
            switch(record.name) {
                case 'Pre Game':
                    return <PreGameIcon style={iconStyle} />;
                case 'First Half':
                    return <FirstHalfIcon style={iconStyle} />;
                case 'Half Time':
                    return <HalfTimeIcon style={iconStyle} />;
                case 'Second Half':
                    return <SecondHalfIcon style={iconStyle} />;
                case 'Extra Time':
                    return <ExtraTimeIcon style={iconStyle} />;
                default:
                    return <FullTimeIcon style={iconStyle} />;
            }
        }
        return <span><Icon />{record.name}</span>;
    };
    
    return <SelectInput
		source={source}
		label={label}
		validate={[required()]}
		choices={statuses}
		optionText={<OptionTextRenderer />}
		optionValue="name" />;
};

export default MatchStatusSelect;