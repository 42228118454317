const configData = {
    Dev: {
        API: 'https://localhost:7125/api',
        NRLCompetitionId: 'eb18b304-614e-4511-bc5a-4ba816310a33',
        PoolCompetitionIds: [
            'c91f7435-3860-455b-9bea-cf0fc796ec8d',
            'f209b605-f4dc-49d7-b7c5-bfd51ea803aa'
        ],
        CurrentSeasonId: 'c4129a25-b5ac-4b23-8a86-4305a82f83b0',
        CurrentCompetitionSeasonId: '8d9467b1-2571-45b6-8e4d-c0e070b4ca1e',
        MyTeamId: '38cbea43-ae2b-4670-8693-a13a4ef03966',
        MyTeamCodes: ['STHS', 'AUS'],
        DNPPositionId: 'e03afe72-4280-4968-95a4-da38ec5a2dda'
    },
    Prod: {
        API: 'https://rl.couri.dev/api',
        NRLCompetitionId: 'eb18b304-614e-4511-bc5a-4ba816310a33',
        PoolCompetitionIds: [
            'c91f7435-3860-455b-9bea-cf0fc796ec8d',
            'f209b605-f4dc-49d7-b7c5-bfd51ea803aa'
        ],
        CurrentSeasonId: 'c4129a25-b5ac-4b23-8a86-4305a82f83b0',
        CurrentCompetitionSeasonId: '8d9467b1-2571-45b6-8e4d-c0e070b4ca1e',
        MyTeamId: '38cbea43-ae2b-4670-8693-a13a4ef03966',
        MyTeamCodes: ['STHS', 'AUS'],
        DNPPositionId: 'e03afe72-4280-4968-95a4-da38ec5a2dda'
    }
};


const devDomains = ['localhost:3000', '10.25.0.10:3000'];
const prodDomains = ['rl.couri.dev', 'rl.anthonycouri.com'];

const domain = window.location.host;

let config = null;
if (devDomains.includes(domain)) {
    config = configData.Dev;
} else if (prodDomains.includes(domain)) {
    config = configData.Prod;
}

export default config;