import * as React from "react";
import { 
    List,
    Datagrid, SimpleList, Filter,
    TextField, DateField, BooleanField, FunctionField, 
    ReferenceInput, SelectInput, 
    useGetOne, useListContext
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Config from "../providers/configProvider";
import LogoField from '../components/LogoField';
import TimeWithZoneField from '../components/TimeWithZoneField';

import TemperatureIcon from '@material-ui/icons/AcUnit';
import RainIcon from '@material-ui/icons/BeachAccess';
import WindIcon from '@material-ui/icons/Flag';

import { LineChart, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, ReferenceLine, Bar, LabelList, ResponsiveContainer } from "recharts";

const useStyles = makeStyles(
    {
        tertiary: {
            opacity: '0.875',
            fontSize: '15px'
        }
    },
    {
        name: 'RaSimpleList'
    }
);

const getNumberWithOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    const suffix = s[(v - 20) % 10] || s[v] || s[0];

    return <span>{n}<span style={{verticalAlign:'top',fontSize:'.75em'}}>{suffix}</span></span>;
};

export const TeamResultList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles(props);

    const MatchFilter = props => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        return (
            <Filter {...props} style={{marginTop: isSmall ? '0px' : '-16px'}}>
                <ReferenceInput label="Season" source="seasonId" reference="season" perPage={1000} allowEmpty={false} alwaysOn>
                    <SelectInput optionText="year" />
                </ReferenceInput>
                <ReferenceInput label="Team" source="teamId" reference="team" perPage={1000} allowEmpty={false} alwaysOn>
                    <SelectInput optionText={<LogoField type="teams" source="code" text="name" />} />
                </ReferenceInput>
            </Filter>
        );
    };

    const ListRowStyle = (record, index) => ({
        backgroundColor: record ? (record.extraTime === true ? '#f9f5de' : (new Date(record.startDateTime).toLocaleDateString('sv-SE') === new Date().toLocaleDateString('sv-SE') ? '#f0f0f0' : '#fff')) : '#fff'
    });

    
    const GetMatchWeather = (id) => {
        const { data, loading, error } = useGetOne('match-weather', id);
        return { data, loading, error };
    };

    const MatchWeather = ({id, weather}) => {
        if (weather === undefined || weather === null) {
            const { data, loading, error } = GetMatchWeather(id);
            if (loading) return <span>Loading...</span>;
            if (error) return <span>No weather available</span>;
            weather = data.observation;
        }

        const iconStyle = { height:'18px', width:'18px', verticalAlign:'middle', paddingRight:0 };

        return (
            <span>
                <span style={{display:'inline-block',width:'85px'}}><TemperatureIcon style={iconStyle} /> {weather.temperatureCelcius} °C</span>
                <span style={{display:'inline-block',width:'85px'}}><RainIcon style={iconStyle} /> {weather.rainMillimetres === null ? 0 : weather.rainMillimetres} mm</span>
                <span><WindIcon style={iconStyle} /> {weather.windSpeedKmH === null ? 0 : weather.windSpeedKmH} km/h</span>
            </span>
        );
    };

    const MatchCharts = () => {
        const { data, loaded } = useListContext();
        if (!loaded) return null;
        if (data === []) return null;

        const teamResults = Object.keys(data).map(key => ({
            roundName: data[key].roundName ? data[key].roundName.split(' ').map(x => isNaN(x) ? x.substring(0,1) : x).join('') : data[key].roundName,
            result: (data[key].filteredTeamCode === data[key].awayTeamCode && data[key].awayTeamScore > data[key].homeTeamScore) || (data[key].filteredTeamCode === data[key].homeTeamCode && data[key].homeTeamScore > data[key].awayTeamScore) ? 'W' : 
                (data[key].homeTeamScore === data[key].awayTeamScore ? 'D' : 'L'),
            isFinal: data[key].roundName ? !data[key].roundName.startsWith('Round') : false,
            ladderPosition: data[key].filteredTeamCode === data[key].awayTeamCode ? data[key].awayTeamLadderPosition : data[key].homeTeamLadderPosition,
            pointsScored: data[key].filteredTeamCode === data[key].awayTeamCode ? data[key].awayTeamScore : data[key].homeTeamScore,
            pointsConceded: data[key].filteredTeamCode === data[key].awayTeamCode ? data[key].homeTeamScore : data[key].awayTeamScore
        }));

        return (
            <div style={{width:"100%"}}>
                <div style={{width:"50%",display:"inline-block"}}>         
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={teamResults.filter(x => !x.isFinal)} margin={{ top: 25, right: 25, left: 10, bottom: 10 }}>
                            <XAxis dataKey="roundName" reversed />
                            <YAxis tickCount={6} domain={[1, 16]} label={{ value: 'Ladder Position', angle: -90, position: 'insideLeft', offset: 20 }} reversed />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <ReferenceLine y={8} stroke="#666" strokeDasharray="3 3" />
                            <Line type="linear" dataKey="ladderPosition" name="Position" stroke="#82ca9d" strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div style={{width:"50%",display:"inline-block"}}>         
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={teamResults} margin={{ top: 25, right: 25, left: 10, bottom: 10 }}>
                            <XAxis dataKey="roundName" reversed />
                            <YAxis tickCount={6} domain={[0, dataMax => dataMax > 50 ? dataMax : 50]} label={{ value: 'Points', angle: -90, position: 'insideLeft', offset: 20 }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Bar dataKey="pointsScored" name="Scored" fill="#82ca9d">
                                <LabelList dataKey="result" position="top" />
                            </Bar>
                            <Bar dataKey="pointsConceded" name="Conceded" fill="#ffc658" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    };

    return (
        <List {...props} bulkActionButtons={false} filters={<MatchFilter />} filterDefaultValues={{ seasonId: Config.CurrentSeasonId, teamId: Config.MyTeamId }} pagination={null} perPage={1000}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => <span>{record.roundName} - {record.isBye ? 'BYE' : (record.venueName && record.venueName.length > 25 ? record.venueNickname : record.venueName)}</span>}
                    secondaryText={record => record.isBye ? null :
                        <LogoField
                            type="teams"
                            source={record.filteredTeamCode === record.awayTeamCode ? "homeTeamCode" : "awayTeamCode"}
                            text={record.competitionCode === 'NRL' ? 
                                (record.filteredTeamCode === record.awayTeamCode ? "homeTeamNickname" : "awayTeamNickname") :
                                (record.filteredTeamCode === record.awayTeamCode ? "homeTeamName" : "awayTeamTeam")}
                            additionalText={`(${record.filteredTeamCode === record.awayTeamCode ? "A" : "H"}${record.neutralVenue === true ? " N" : ""})${record.attended ? " - Attended" : ""}`}
                        />
                    }
                    tertiaryText={record => (
                        <div>
                            <span style={{display:'inline-block',width:'30px',textAlign:'right'}}>
                                {record.isBye ? 'B' : ((record.filteredTeamCode === record.awayTeamCode && record.awayTeamScore > record.homeTeamScore) || (record.filteredTeamCode === record.homeTeamCode && record.homeTeamScore > record.awayTeamScore) ? 'W' : 
                                (record.homeTeamScore === record.awayTeamScore ? 'D' : 'L'))}
                            </span>
                            <span style={{display:'inline-block',width:'60px',textAlign:'right'}}>
                                {record.isBye ? '' : (record.filteredTeamCode === record.awayTeamCode ? `${record.awayTeamScore}-${record.homeTeamScore}` : `${record.homeTeamScore}-${record.awayTeamScore}`)}
                            </span>
                        </div>
                    )}
                    linkType={(record, id) => `/match/${id}/show`}
                    classes={classes}
                    rowStyle={ListRowStyle}
                />
            ) : (
                <React.Fragment>
                    <MatchCharts />
                    <Datagrid rowClick={(id) => `/match/${id}/show`} rowStyle={ListRowStyle}>
                        <LogoField type="competitions" source="competitionCode" text="competitionCode" label="Competition" />
                        <TextField source="seasonYear" label="Season" />
                        <TextField source="roundName" label="Round" />
                        <FunctionField render={record =>
                            record.isBye ? 'B' :
                            ((record.filteredTeamCode === record.awayTeamCode && record.awayTeamScore > record.homeTeamScore) || (record.filteredTeamCode === record.homeTeamCode && record.homeTeamScore > record.awayTeamScore) ? 'W' : 
                            (record.homeTeamScore === record.awayTeamScore ? 'D' : 'L'))
                        } label="Result" />
                        <FunctionField render={record => record.competitionCode === 'NRL' 
                            ? <LogoField type="teams" source={record.filteredTeamCode === record.awayTeamCode ? "homeTeamCode" : "awayTeamCode"} text={record.filteredTeamCode === record.awayTeamCode ? "homeTeamNickname" : "awayTeamNickname"} />
                            : <LogoField type="teams" source={record.filteredTeamCode === record.awayTeamCode ? "homeTeamCode" : "awayTeamCode"} text={record.filteredTeamCode === record.awayTeamCode ? "homeTeamName" : "awayTeamTeam"} />
                        } label="Opponent" />
                        <FunctionField render={record => <span>{record.isBye ? '' : (record.filteredTeamCode === record.awayTeamCode ? `${record.awayTeamScore}-${record.homeTeamScore}` : `${record.homeTeamScore}-${record.awayTeamScore}`)}</span>} label="Score" />
                        <FunctionField render={record => <span>{record.isBye ? '' : (record.filteredTeamCode === record.awayTeamCode ? "A" : "H")}{record.neutralVenue === true ? " N" : ""}</span>} label="H/A/N" />
                        <TextField source="venueName" label="Venue" />
                        <TextField source="refereeName" label="Referee" />
                        <DateField source="startDateTime" label="Date" options={{ weekday: 'short', day: 'numeric', month: 'long' }} locales="en-AU" />
                        <TimeWithZoneField source="startDateTime" includeDate={false} label="Time" />
                        <FunctionField render={record => record.isBye || record.status === 'Pre Game' ? null : <MatchWeather id={record.id} weather={record.weather} />} label="Weather" />
                        <FunctionField render={record => record.attended === true ? <BooleanField source="attended" /> : null} label="Attended?" />
                        <FunctionField render={record => getNumberWithOrdinal(record.filteredTeamCode === record.awayTeamCode ? record.awayTeamLadderPosition : record.homeTeamLadderPosition)} label="Ladder Position" />
                    </Datagrid>
                </React.Fragment>
            )}
        </List>
    );
};
