import * as React from "react";
import { 
    List, Show, Edit, Create, 
    Datagrid, SimpleShowLayout, SimpleForm, Pagination,
    TextField,
    TextInput,
    required, useNotify, useRedirect, useShowController
} from 'react-admin';
import LogoField from '../components/LogoField';
import DateTimeField from '../components/DateTimeField';

export const TeamList = props => {
    const ListPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;    
    
    return (
        <List {...props} bulkActionButtons={false} pagination={<ListPagination />} perPage={25}>
            <Datagrid rowClick="show">
                <LogoField type="teams" source="code" text="name" label="Name" />
                <TextField source="nickname" label="Nickname" />
                <TextField source="code" label="Code" />
            </Datagrid>
        </List>
    );
};

export const TeamShow = props => {
    const { loading } = useShowController(props);
    if (loading) return null;

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" label="ID" />
                <LogoField type="teams" source="code" text="name" label="Name" />
                <TextField source="nickname" label="Nickname" />
                <TextField source="code" label="Code" />
                <DateTimeField source="createdUtc" label="Created" />
                <DateTimeField source="lastUpdatedUtc" label="Last Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const TeamEdit = props => (
    <Edit {...props} mutationMode="optimistic">
        <SimpleForm>
            <TextInput source="id" label="ID" fullWidth={true} disabled />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
            <TextInput source="nickname" label="Nickname" fullWidth={true} validate={[required()]} />
            <TextInput source="code" label="Code" fullWidth={true} validate={[required()]} format={x => x == null ? null : x.toUpperCase()} />
        </SimpleForm>
    </Edit>
);

export const TeamCreate = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
		notify(`Team '${data.name}' successfully created.`);
		redirect(`/team/${data.id}/show`);
	};

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} />
                <TextInput source="nickname" label="Nickname" fullWidth={true} validate={[required()]} />
                <TextInput source="code" label="Code" fullWidth={true} validate={[required()]} format={x => x == null ? null : x.toUpperCase()} />
            </SimpleForm>
        </Create>
    );
};