import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import Typography from '@material-ui/core/Typography';

import ByeIcon from '@material-ui/icons/NotInterested';
import PreGameIcon from '@material-ui/icons/AccessTime';
import FirstHalfIcon from '@material-ui/icons/AvTimer';
import HalfTimeIcon from '@material-ui/icons/PauseCircleOutline';
import SecondHalfIcon from '@material-ui/icons/AvTimer';
import ExtraTimeIcon from '@material-ui/icons/AddCircleOutline';
import FullTimeIcon from '@material-ui/icons/CheckCircleOutline';

const MatchStatusField = (props) => {
    const { source, iconOnly } = props;
    const record = useRecordContext(props);
    const status = record[source];

    const iconStyle = { height: '20px', margin: '0 2px 0 0', verticalAlign: 'middle' };

    const Icon = () => {
        switch(status) {
            case 'BYE':
                return <ByeIcon style={iconStyle} />;
            case 'Pre Game':
                return <PreGameIcon style={iconStyle} />;
            case 'First Half':
                return <FirstHalfIcon style={iconStyle} />;
            case 'Half Time':
                return <HalfTimeIcon style={iconStyle} />;
            case 'Second Half':
                return <SecondHalfIcon style={iconStyle} />;
            case 'Extra Time':
                return <ExtraTimeIcon style={iconStyle} />;
            default:
                return <FullTimeIcon style={iconStyle} />;
        }
    }
    
    return <Typography component="span" variant="body2"><Icon />{iconOnly ? '' : status}</Typography>;
}  

MatchStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

MatchStatusField.defaultProps = {
    addLabel: true,
    sortable: false
};

export default MatchStatusField;